/* Author: 劉喆 John Liu
   License: GNU General Public License Version 3 (GPLv3) */

.mainmenu {
}

.menu {
  margin: 10px auto;
  font-family: frontfont;
  height: 100px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 72px;
  font-weight: 400;
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: 250ms;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

* {
      touch-action: manipulation;
  }

.disable-doubletap-to-zoom {
    touch-action: none;
}


.line-above-buttons {
  width: 100%;
  height: 1px;
  background-color: #000; /* Adjust the color as needed */
  margin-bottom: 10px; /* Adjust the spacing between the line and buttons */
}

.footer {
  text-align: center;
  color: #eeeeee;
  margin-top: 1em;
}

.topbodyb {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  touch-action: manipulation;
}

.topbody {
  width: var(--layout-width);
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.topbody button {
  border: none;
  background: #111122;
  color: white;
  padding: 15px 20px;
  border-radius: 15px;
  margin: 0.6em;
  margin-top: 10px;
  background-color: black;
  opacity: 0.85;
  transition: all 0.25s;
  cursor: pointer;
  line-height: 48px;
  display: inline-flex;
  touch-action: manipulation;
  transition: background-color 0.3s ease;
  position: relative; 
  justify-content: center;
  align-items: center;
}

.topbody button:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.topbody button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  transform: translateY(0px);
}

.topbody .toggle-button{
  padding: 15px 14px;
}

.topbody .toggle-button img {
  height: 25px;
}

.topbody .toggle-button.隨機順序 {
  background-color: #006600;
}

.topbody .toggle-button.正常秩序 {
  background-color: black;
  opacity: 30%;
}

.topbody .speakerButton {}

.topbody .speakerButton img {
  height: 32px;
}

.topbody .userListButton {}

.topbody .userListButton img {
  height: 30px;
}

.topbody .homeButton {}

.topbody .homeButton img {
  height: 32px;
}


.overlay-container {
  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #fff; /* #87898c Adjust the color as needed */
  border: none;
  margin: 0; /* Remove default margin to avoid unexpected spacing */
}

.overlay-text {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
  background-color: #242934; /* Adjust the background color as needed */
  padding: 0 0px; /* Adjust the padding as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.homebutton {
  border: none;
  border-radius: 15px;
  font-size: 16px;
  position: relative; 
  background: #111122;
  margin: 10px;
  display: inline-flex;
  padding: 8px 15px;
  justify-content: center;
}

.homebutton img {
  height: 55px;
}


.basemenu {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  touch-action: manipulation;
}

.basemenu button {
  border: none;
  background: #111122;
  color: white;
  border-radius: var(--border-radius);
  margin: 0.4em;
  opacity: 0.9;
  transition: all 0.25s;
  cursor: pointer;
  font-size: 30px;
  font-family: 'frontfont';
  justify-content: center;
  line-height: 36px;
  height: 148px;
  display:flex;
  align-items: center;
  touch-action: manipulation;
  padding: 20px;
}

.basemenu button:hover {
  opacity: 1;
  transform: translateY(-2px);
}
